import React from 'react'

import { RequestSubscriptionForm } from './RequestSubscriptionForm'
import { Modal } from './Modal'

const RequestSubscriptionOrderingModal = ({
	activeTab,
	onClose,
	selectedTypeValue,
}: {
	activeTab: number
	onClose(): void
	selectedTypeValue: string
}) => {
	return (
		<Modal {...{ onClose }}>
			<RequestSubscriptionForm
				activeTab={activeTab}
				packageOption={selectedTypeValue}
			></RequestSubscriptionForm>
		</Modal>
	)
}

export { RequestSubscriptionOrderingModal }
