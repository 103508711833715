import React from 'react'
import styled from 'styled-components'

import { Footer } from '../components/footer'
import { Header } from '../components/header'

const Container = styled.div<{ withBoxShadow: boolean }>`
	display: flex;
	flex-direction: column;

	min-height: 100vh;

	main {
		display: flex;
		align-items: center;
		flex: 1;

		.container {
			display: flex;
			flex-direction: column;
			box-shadow: ${({ withBoxShadow }) =>
				withBoxShadow ? '0 0 60px rgba(0, 0, 0, 0.1);' : null};
		}
	}
`

const DefaultPageLayout = ({
	children,
	withBoxShadow,
}: React.PropsWithChildren<{ withBoxShadow: boolean }>) => (
	<Container withBoxShadow={withBoxShadow}>
		<Header />
		<main>
			<div className="container">{children}</div>
		</main>
		<Footer />
	</Container>
)

DefaultPageLayout.defaultProps = {
	withBoxShadow: true,
}

export { DefaultPageLayout }
