import React, { useEffect } from 'react'

interface HubSpotFormConfig {
	region: string
	portalId: string
	formId: string
	target: string
}

interface HubspotFormProps {
	formId: string
}

declare global {
	interface Window {
		hbspt: {
			forms: {
				create: (config: HubSpotFormConfig) => void
			}
		}
	}
}

const HubspotForm: React.FC<HubspotFormProps> = ({ formId }) => {
	useEffect(() => {
		const script: HTMLScriptElement = document.createElement('script')
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.type = 'text/javascript'
		document.body.appendChild(script)

		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					region: 'na1',
					portalId: '3797785',
					formId,
					target: '#hubspotForm',
				})
			}
		})

		return () => {
			document.body.removeChild(script)
		}
	}, [formId])

	return <div id="hubspotForm"></div>
}

export { HubspotForm }
