import { Locale } from 'locale-enum'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HubspotForm } from './HubspotForm'

const ContactForm: React.FC = () => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language

	const formId =
		currentLanguage === Locale.de_DE
			? 'e9ca22a5-8409-410e-90d2-87280426cdd2'
			: '1c5d123f-1a0c-41ec-b2af-21d7e98ec296'

	return <HubspotForm formId={formId} />
}

export { ContactForm }
