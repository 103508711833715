import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Reaptcha from 'reaptcha'
import styled from 'styled-components'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { FieldText } from '../../components/fields/Text'
import { FieldTextArea } from '../../components/fields/TextArea'
import { FieldToggle } from '../../components/fields/Toggle'
import { FormFeedbackModal } from '../../components/FormFeedbackModal'
import { ContactForm } from '../../components/ContactForm'
import { PageHeader } from '../../components/PageHeader'
import {
	AIRTEAM_EMAIL,
	CONTACT_LOCATION_URL,
	GOOGLE_RECAPTCHA_SITE_KEY,
} from '../../data'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { PRIVACY_POLICY_PATH } from '../legal/PrivacyPolicyView'

import emailIcon from './assets/icons/email.svg'
import locationIcon from './assets/icons/location.svg'
import phoneIcon from './assets/icons/phone.svg'
import person from './assets/person.jpeg'
import titleBackground from './assets/title-background.jpg'
import { useContactForm } from './hooks'

const Link = styled.a`
	color: ${Color.AT_GREEN_2};

	cursor: pointer;

	font-weight: bold;

	:hover:not(:active) {
		color: ${Color.AT_OCHRE};
		text-decoration: underline;
	}
`

const HubspotContactFormStyle = styled.div`
	.contact-card {
		width: 100%;

		.contact-card__container {
			padding: 50px;
			background: ${Color.AT_WHITE_PRIMARY};
			box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
			border-radius: 10px;

			&__title {
				${getFont(Font.H2_SEMI_BOLD)};
				margin-bottom: 45px;
				text-align: center;
			}
		}
		iframe {
			max-width: 600px;
		}

		#hubspotForm {
			justify-content: center;
			display: flex;
		}
	}
`

const ContactViewStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.contact {
		display: flex;

		@media ${mediaBreakpoint.small} {
			flex-direction: column;
			align-items: center;

			&__person {
				margin-bottom: 30px;
			}
		}

		@media ${mediaBreakpoint.mediumPlus} {
			flex-direction: row;
			align-items: flex-end;

			&__person {
				margin-right: 100px;
			}
		}

		&__person {
			display: flex;
			flex-direction: column;
			align-items: center;

			&__image {
				border-radius: 50%;
				height: 256px;
				width: 256px;
				background-size: cover;
				background-position: center center;
				margin-bottom: 25px;

				transform: translateZ(0);
				will-change: transform;
				filter: saturate(0%);
			}

			&__title {
				${getFont(Font.B0_REGULAR)};
			}

			&__name {
				${getFont(Font.B0_BOLD)};
			}
		}

		&__info {
			> * {
				display: flex;
				align-items: center;

				&:not(:first-child) {
					margin-top: 35px;
				}

				> *:not(:first-child) {
					margin-left: 40px;
				}
			}

			&__image {
				width: 80px;
				display: flex;
				align-items: center;
				flex-direction: column;
			}

			&__label-value,
			a {
				color: ${Color.AT_BLACK_NOT_1};
			}

			a:hover {
				color: ${Color.AT_GREEN_2};
			}

			&__label {
				${getFont(Font.B0_REGULAR)};
			}

			&__value {
				${getFont(Font.B0_BOLD)};
			}
		}
	}

	.contact-card {
		width: 100%;
		margin: 65px 156px;

		.contact-card__container {
			padding: 50px;
			background: ${Color.AT_WHITE_PRIMARY};
			box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
			border-radius: 10px;

			display: flex;
			align-items: center;
			flex-direction: column;

			&__title {
				${getFont(Font.H2_SEMI_BOLD)};
				margin-bottom: 45px;
			}
		}
	}

	.map {
		margin: 65px 156px;
		width: 100%;
		border: 1px solid #c5c7c8;
		border-radius: 10px;
		overflow: hidden;

		iframe {
			border: 0;
			width: 100%;
			height: 300px;
		}
	}
`

const ContactView = () => {
	useScrollToTop()
	document.title = 'Kontakt | AIRTEAM'
	const { t } = useTranslation()
	const contactForm = useContactForm()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isWaitingOnServer, setIsWaitingOnServer] = useState(false)
	const [isRequestSuccessful, setIsRequestSuccessful] = useState<
		boolean | null
	>(null)

	return (
		<DefaultPageLayout>
			{isModalOpen && (
				<FormFeedbackModal
					isLoading={isWaitingOnServer}
					message={
						isRequestSuccessful
							? t('views.contact.formFeedback.success.message')
							: t('views.contact.formFeedback.failure.message')
					}
					title={
						isRequestSuccessful
							? t('views.contact.formFeedback.success.title')
							: t('views.contact.formFeedback.failure.title')
					}
					onClose={() => setIsModalOpen(false)}
				/>
			)}
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.contact.description')}
			/>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.contact.title'),
				}}
				top="in"
			>
				<ContactViewStyle>
					<div className="contact">
						<div className="contact__person">
							<div
								className="contact__person__image"
								style={{ backgroundImage: `url(${person})` }}
							/>
							<div className="contact__person__title">
								{t('views.contact.contactPersonTitle')}
							</div>
							<div className="contact__person__name">Carolin Wilke</div>
						</div>
						<div className="contact__info">
							<div className="contact__info__phone">
								<div className="contact__info__image">
									<img alt="phone" src={phoneIcon} />
								</div>
								<div className="contact__info__label-value">
									<div className="contact__info__label">
										{t('views.contact.info.callLabel')}
									</div>
									<div className="contact__info__value">
										<a href="tel:+493037580830">+49 30 37 580 830</a>
									</div>
								</div>
							</div>
							<div className="contact__info__email">
								<div className="contact__info__image">
									<img alt="email" src={emailIcon} />
								</div>
								<div className="contact__info__label-value">
									<div className="contact__info__label">
										{t('views.contact.info.emailLabel')}
									</div>
									<div className="contact__info__value">
										<a href={`mailto:${AIRTEAM_EMAIL}`}>{AIRTEAM_EMAIL}</a>
									</div>
								</div>
							</div>
							<div className="contact__info__location">
								<div className="contact__info__image">
									<img alt="location" src={locationIcon} />
								</div>
								<div className="contact__info__label-value">
									<div className="contact__info__label">
										{t('views.contact.info.locationLabel')}
									</div>
									<div className="contact__info__value">
										<a
											href={CONTACT_LOCATION_URL}
											rel="noopener noreferrer"
											target="_blank"
										>
											{t('views.contact.info.address1')}
											<br />
											{t('views.contact.info.address2')}
											<br />
											{t('views.contact.info.address3')}
											<br />
											{t('views.contact.info.address4')}
											<br />
											{t('views.contact.info.address5')}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="map">
						<iframe
							allowFullScreen={false}
							aria-hidden="false"
							frameBorder="0"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d310846.6595279741!2d13.144223569920008!3d52.50647942170938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84f008d108c81%3A0x85b8b87a4b5269e7!2sZeppelin%20Lab%20GmbH!5e0!3m2!1sen!2seg!4v1658271129898!5m2!1sen!2seg"
							tabIndex={0}
							title="Google Maps Location Embed for Airteam"
						/>
					</div>
				</ContactViewStyle>
				<HubspotContactFormStyle>
					<div className="contact-card">
						<div className="contact-card__container">
							<div className="contact-card__container__title">
								{t('views.contact.card.title')}
							</div>
							<ContactForm></ContactForm>
						</div>
					</div>
				</HubspotContactFormStyle>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const CONTACT_PATH = '/contact' as const

export { ContactView }
