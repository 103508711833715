import { Locale } from 'locale-enum'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Carousel } from '../../../components/Carousel'
import { TryForFree } from '../../../components/TryForFree'
import {
	ARROW_HEIGHT_LARGE,
	ARROW_HEIGHT_MEDIUM,
	ARROW_HEIGHT_SMALL,
} from '../../../data'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import {
	Color,
	Font,
	getFont,
	makePattern,
	mediaBreakpoint,
} from '../../../theme'
import { EUROPEAN_UNION_PATH } from '../../european-union/View'
import climAcceleratorLogo from '../assets/ClimAccelerator.png'
import dinCertifiedDe from '../assets/din-certified/de.svg'
import dinCertifiedEn from '../assets/din-certified/en.svg'
import eitLogo from '../assets/EIT.png'
import euLogo from '../assets/eu-logo.jpg'
import hero_1 from '../assets/hero_1.webp'
import hero_1_small from '../assets/hero_1_small.webp'
import hero_2 from '../assets/hero_2.webp'
import hero_2_small from '../assets/hero_2_small.webp'
import hero_3 from '../assets/hero_3.webp'
import hero_3_small from '../assets/hero_3_small.webp'
import partner1 from '../assets/partners/1.png'

const MainHeading = styled.h1`
	margin: 0;
	color: ${Color.AT_WHITE_PRIMARY};
	font-weight: 800;
	width: 85%;

	${getFont(Font.H0)};
	line-height: 100px;
	letter-spacing: 0.03em;
	font-size: 80px;
	padding-top: 145px;

	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_H0_BOLD)};
		line-height: 12vw;
		letter-spacing: 0.5vw;
		font-size: 11vw;
		padding-top: 145px;

		width: 100%;
	}

	@media ${mediaBreakpoint.medium} {
		width: 75%;
		line-height: 7vw;
		letter-spacing: 0.25vw;
		font-size: 6vw;
	}
`

const MainContent = styled.h2`
	color: ${Color.AT_WHITE_PRIMARY};
	letter-spacing: 0.03em;
	font-weight: 400 !important;

	strong {
		font-weight: 900;
	}
	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_H4_MEDIUM)};
		width: 80%;
	}

	@media ${mediaBreakpoint.medium} {
		${getFont(Font.H4_MEDIUM)};
		width: 70%;
		line-height: 3vw;
		letter-spacing: 0.1vw;
		font-size: 2vw;
	}

	@media ${mediaBreakpoint.large} {
		${getFont(Font.H4_MEDIUM)};
		width: 60%;
	}
`

interface MainBlockProps {
	hero?: string
	heroSmall?: string
}

const MainBlock = styled.div<MainBlockProps>`
	width: 100%;

	background-image: url(${(props) => props.hero});
	background-size: cover;
	height: 826px;
	z-index: 2;
	text-align: left;
	display: flex;

	.preload::after {
		position: absolute;
		width: 0;
		height: 0;
		overflow: hidden;
		z-index: -1;
		content: url(${hero_1}) url(${hero_2}) url(${hero_3});

		@media ${mediaBreakpoint.small} {
			content: url(${hero_1_small}) url(${hero_2_small}) url(${hero_3_small});
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	}

	@media ${mediaBreakpoint.small} {
		background-image: url(${(props) => props.heroSmall});
		background-position: center;
	}

	.hero-text {
		width: 70%;
		padding-left: 85px;

		@media ${mediaBreakpoint.small} {
			padding-left: 10vw;
			width: 85%;
		}
	}
`

const TitleBlock = styled.section`
	position: relative;

	display: flex;
	justify-content: center;
	text-align: center;

	@media ${mediaBreakpoint.small} {
		margin-bottom: ${ARROW_HEIGHT_MEDIUM}px;
	}

	@media ${mediaBreakpoint.medium} {
		margin-bottom: ${ARROW_HEIGHT_MEDIUM}px;
	}

	@media ${mediaBreakpoint.large} {
		margin-bottom: ${ARROW_HEIGHT_LARGE}px;
	}

	.dark-something {
		background-color: ${Color.AT_BLACK_1};
		position: absolute;

		clip-path: polygon(
			100% 33.333%,
			100% 66.667%,
			50% 85%,
			0% 66.667%,
			0% 33.333%,
			50% 15%
		);

		@media ${mediaBreakpoint.small} {
			bottom: -${5 * ARROW_HEIGHT_SMALL}px;
			height: ${12 * ARROW_HEIGHT_SMALL}px;
		}
		@media ${mediaBreakpoint.medium} {
			bottom: -${5 * ARROW_HEIGHT_SMALL}px;
			height: ${14 * ARROW_HEIGHT_SMALL}px;
		}
		@media ${mediaBreakpoint.large} {
			bottom: -${2.5 * ARROW_HEIGHT_LARGE}px;
			height: ${6.3 * ARROW_HEIGHT_LARGE}px;
		}

		left: 0;
		right: 0;
		background-image: url(${makePattern(Color.AT_GRAY, 10, 1)});
		background-repeat: repeat;
		background-size: 10px 10px;
	}
`

const CTAStyle = styled.div`
	position: relative;
	z-index: 4;
`

const Partners = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;

	@media ${mediaBreakpoint.mediumMinus} {
		justify-content: center;

		> *:first-child {
			margin-right: 6px;
		}
		> *:nth-child(2) {
			margin-bottom: 5px;
		}
		> *:last-child {
			margin-top: 5px;
		}
	}

	@media ${mediaBreakpoint.large} {
		> *:not(:last-child) {
			margin-right: 40px;
		}
	}

	@media ${mediaBreakpoint.mediumMinus} {
		margin: 70px 20px 20px 20px;

		.image-1 {
			max-width: 160px;
			display: inline-block;
		}
		.image-2 {
			max-width: 160px;
		}
		.image-3 {
			max-width: 100px;
		}
		.image-4 {
			max-width: 140px;
			display: inline-block;
			margin-right: 20px;
		}
		.image-5 {
			max-width: 140px;
		}
	}
	@media ${mediaBreakpoint.large} {
		margin: 80px 260px 0 260px;

		.image-1 {
			max-width: 325px;
		}
		.image-2 {
			max-width: 250px;
		}
		.image-3 {
			max-width: 100px;
		}
		.image-4 {
			max-width: 275px;
			display: inline-block;
		}
		.image-5 {
			max-width: 275px;
			display: inline-block;
		}
	}

	div {
		display: flex;
		align-items: center;

		> *:not(:last-child) {
			@media ${mediaBreakpoint.mediumMinus} {
				margin-right: 5px;
			}
			@media ${mediaBreakpoint.large} {
				margin-right: 10px;
			}
		}
	}

	.line-break {
		width: 100%;

		&--hz {
			margin-top: 10px;
		}
	}
`

const KIC_PATH = 'https://www.climate-kic.org/'
const CLIM_ACCELERATOR_PATH = 'https://www.cleancities.network/'

const HomeViewSection1Title = () => {
	const { i18n, t } = useTranslation()
	const { isLarge } = useBreakpoints()

	const currentLanguage = i18n.language

	const carouselItems = [1, 2, 3].map((item) => (
		<div key={item}>
			<MainHeading>{t(`views.home.sections.main.title${item}`)}</MainHeading>
			<MainContent>
				<Trans i18nKey="hello">
					{item === 2 ? (
						<>
							{t(`views.home.sections.main.content${item}Part1`)}
							<br />
							{t(`views.home.sections.main.content${item}Part2`)}
						</>
					) : (
						t(`views.home.sections.main.content${item}`)
					)}
				</Trans>
			</MainContent>
			<div style={{ marginBottom: '20px' }}>
				<CTAStyle>
					<TryForFree />
				</CTAStyle>
			</div>
		</div>
	))

	const [hero, setHero] = useState(hero_1)
	const [heroSmall, setHeroSmall] = useState(hero_1_small)

	const handleCarouselChange = (newIndex: number) => {
		let newHero, newHeroSmall

		if (newIndex === 0) {
			newHero = hero_1
			newHeroSmall = hero_1_small
		} else if (newIndex === 1) {
			newHero = hero_2
			newHeroSmall = hero_2_small
		} else {
			newHero = hero_3
			newHeroSmall = hero_3_small
		}

		setHero(newHero)
		setHeroSmall(newHeroSmall)
	}

	return (
		<>
			<TitleBlock>
				<MainBlock hero={hero} heroSmall={heroSmall}>
					<div className="overlay"></div>
					<div className="preload"></div>
					<div className="hero-text">
						<Carousel
							alignButtons="left"
							autoRotate
							items={carouselItems}
							showButtons={false}
							onChange={handleCarouselChange}
						/>
					</div>
				</MainBlock>

				<div className="dark-something" />
			</TitleBlock>
			<Partners>
				<Link to={EUROPEAN_UNION_PATH}>
					<img alt="EU" className="image-1" src={euLogo} />
				</Link>
				<img
					alt="DIN certified"
					className="image-2"
					src={
						currentLanguage === Locale.de_DE ? dinCertifiedDe : dinCertifiedEn
					}
				/>
				{!isLarge && <div className="line-break line-break--hz" />}
				<div>
					<div>{t('views.home.sections.main.partner')}</div>
					<img alt="partner logo" className="image-3" src={partner1} />
				</div>
				<div className="line-break line-break--hz" />
				<a href={KIC_PATH} rel="noopener noreferrer" target="_blank">
					<img alt="Climate-KIC" className="image-4" src={eitLogo} />
				</a>
				<a
					href={CLIM_ACCELERATOR_PATH}
					rel="noopener noreferrer"
					target="_blank"
				>
					<img
						alt="ClimAccelerator"
						className="image-5"
						src={climAcceleratorLogo}
					/>
				</a>
			</Partners>
		</>
	)
}

export { HomeViewSection1Title }
