import React from 'react'

import { RequestDronePackageForm } from './RequestDronePackageForm'
import { Modal } from './Modal'

const RequestDronePackageModal = ({
	onClose,
	selectedTypeValue,
}: {
	onClose(): void
	selectedTypeValue: string
}) => {
	return (
		<Modal {...{ onClose }}>
			<RequestDronePackageForm
				packageOption={selectedTypeValue}
			></RequestDronePackageForm>
		</Modal>
	)
}

export { RequestDronePackageModal }
