import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from '../../assets/Logo'
import { LOGIN_URL } from '../../data'
import { Color, Font, getFont } from '../../theme'
import { HOME_PATH } from '../../views/home/View'
import { Button } from '../Button'
import { TryForFree } from '../TryForFree'

import { useMenu } from './hooks/use-menu'
import { HeaderLocaleSwitcher } from './LocaleSwitcher'
import { ExternalEntry, LinkEntry, NestedEntry } from './types'

const Nav = styled.header`
	top: 0;
	left: 0;
	right: 0;
	height: 70px;
	border-bottom: 2px solid ${Color.AT_GREEN_1};
	z-index: 9001;

	background-color: ${Color.AT_BLACK_1};

	display: flex;
	align-items: center;
	justify-content: space-between;
`

/**
 * Intentionally has a 70x70 click area because it’s supposed to be used
 * on mobile, where accidentally clicking next to it would be common.
 */
const Bars = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 70px;
	width: 70px;

	> * {
		height: 2px;
		border-radius: 1px;
		background-color: ${Color.AT_GREEN_1};
		width: 30px;
	}

	&:hover > * {
		background-color: ${Color.AT_GREEN_2};
	}

	> *:not(:first-child) {
		margin-top: 8px;
	}
`

const Menu = styled.nav`
	left: 0;
	right: 0;
	bottom: 0;
	top: 70px;
	overflow-x: auto;

	background-color: rgba(33, 52, 53, 0.95); /* Color.AT_BLACK_2 */
	z-index: 9001;

	display: flex;
	align-items: center;
	flex-direction: column;

	padding-top: 42px;

	transform: translateZ(0);
	will-change: transform;
	backdrop-filter: blur(3px);

	> div {
		display: flex;
		align-items: center;
		flex-direction: column;

		&.links {
		}

		&.buttons {
			margin-top: 60px;

			> *:not(:first-child) {
				margin-top: 30px;
			}
		}

		&.locale {
			margin-top: 60px;
			transform: scale(2);
		}
	}
`

const LogoContainer = styled.div`
	cursor: pointer;

	> * {
		height: 35px;
	}
`

const HeaderLinksStyle = styled.div`
	${getFont(Font.B0_BOLD)}
	color: ${Color.AT_WHITE_PRIMARY};

	display: flex;
	align-items: center;
	flex-direction: column;

	> * {
		cursor: pointer;

		&.active {
			color: ${Color.AT_GREEN_1};
			font-weight: bold;
		}

		:hover {
			color: ${Color.AT_GREEN_1};
		}

		&:not(:first-child) {
			margin-top: 33px;
		}
	}
`

const Link = ({ go, label, to }: LinkEntry & { go(to: string): void }) => {
	const location = useLocation()

	return (
		<div
			className={location.pathname === to ? 'active' : ''}
			onClick={() => go(to)}
		>
			{label}
		</div>
	)
}

const External = ({ label, to }: ExternalEntry) => {
	const location = useLocation()

	return (
		<div className={location.pathname === to ? 'active' : ''}>
			<a
				href={to}
				rel="noopener noreferrer"
				style={{ all: 'unset' }}
				target="_blank"
			>
				{label}
			</a>
		</div>
	)
}

const NestedStyle = styled.div`
	text-align: center;

	&.active {
		color: ${Color.AT_GREEN_1};
		font-weight: bold;
	}

	:hover {
		color: ${Color.AT_GREEN_1};
	}
`

const NestedChildrenStyle = styled.div<{ isOpen: boolean }>`
	transition: all ease-in-out 250ms;

	height: ${({ isOpen }) => (isOpen ? '70px' : 0)};
	opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
	pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
	color: ${Color.WHATEVER_GREEN};

	display: flex;

	> * {
		margin-top: 33px;

		&.active {
			color: ${Color.AT_GREEN_1};
			font-weight: bold;
		}

		:hover {
			color: ${Color.AT_GREEN_1};
		}
	}

	> *:not(:first-child) {
		margin-left: 33px;
	}
`

const Nested = ({
	children,
	go,
	label,
}: NestedEntry & { go(to: string): void }) => {
	const [isOpen, setIsOpen] = useState(false)
	const location = useLocation()

	return (
		<div>
			<NestedStyle
				className={
					children.some((child) => location.pathname === child.to)
						? 'active'
						: ''
				}
				onClick={() => setIsOpen((x) => !x)}
			>
				{label}
			</NestedStyle>
			<NestedChildrenStyle {...{ isOpen }}>
				{children.map((child, index) => {
					switch (child.type) {
						case 'link':
							return <Link key={index} {...{ ...child, go }} />

						case 'external':
							return <External key={index} {...child} />

						default:
							throw new Error('unexpected link type')
					}
				})}
			</NestedChildrenStyle>
		</div>
	)
}

const HeaderMobileLinks = ({ go }: { go(to: string): void }) => {
	const { menu } = useMenu()

	return (
		<HeaderLinksStyle>
			{menu.map((item, index) => {
				switch (item.type) {
					case 'link':
						return <Link key={index} {...{ ...item, go }} />

					case 'external':
						return <External key={index} {...item} />

					case 'nested':
						return <Nested key={index} {...{ ...item, go }} />

					default:
						throw new Error('unexpected link type')
				}
			})}
		</HeaderLinksStyle>
	)
}

const HeaderMobile = () => {
	const history = useHistory()
	const { t } = useTranslation()

	const [isOpen, setIsOpen] = useState(false)

	const go = (to: string) => {
		setIsOpen(false)

		if (to.startsWith('/')) history.push(to)
		else window.location.href = to
	}

	return (
		<>
			<Nav>
				<LogoContainer onClick={() => go(HOME_PATH)}>
					<Logo color={Color.AT_GREEN_1} />
				</LogoContainer>
				<Bars onClick={() => setIsOpen((x) => !x)}>
					<div />
					<div />
					<div />
				</Bars>
			</Nav>
			{isOpen && (
				<Menu>
					<div className="links">
						<HeaderMobileLinks {...{ go }} />
					</div>
					<div className="buttons">
						<Button
							label={t('header.menu.login')}
							type="secondary"
							onClick={() => (window.location.href = LOGIN_URL)}
						/>
						<TryForFree type="yellow" />
					</div>
					<div className="locale">
						<HeaderLocaleSwitcher postLocaleChange={() => setIsOpen(false)} />
					</div>
				</Menu>
			)}
		</>
	)
}

export { HeaderMobile }
