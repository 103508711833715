import React from 'react'
import { JOB_POSTINGS } from '../../data'
import { createGlobalStyle } from 'styled-components'

const KarriereGlobalStyles = createGlobalStyle`
  #root {
		zoom: 100% !important;
		overflow: hidden;
	}

`
const KarriereView = () => {
	return (
		<>
			<KarriereGlobalStyles />
			<div style={{ height: '100vh', width: '100%' }}>
				<iframe
					src={JOB_POSTINGS}
					style={{ border: 'none', width: '100%', height: '100%' }}
					title="Karriere"
				/>
			</div>
		</>
	)
}

export const karriereBasePath = '/karriere' as const

export { KarriereView }
